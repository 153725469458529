import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { IoClose, IoMenu } from 'react-icons/io5';

import ActiveLink from '@/common/active-link';
import Button from '@/common/button';
import SignInModal from '@/components/modals/sign-in-modal';

import LanguageSwitcher from './language-switcher';

export default function Header({ variant }: { variant?: 'primary' | 'secondary' }) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [buttonClass, setButtonClass] = useState('pulse-circle-white');
  const [paddingClass, setPaddingClass] = useState('py-5');
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setButtonClass('pulse-circle-green');
        setPaddingClass('py-2.5');
      } else {
        setButtonClass('pulse-circle-white');
        setPaddingClass('py-5');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <div className='sticky top-0 z-50 flex w-full items-center justify-center bg-black/60 backdrop-blur-lg'>
        <div className={`flex w-full max-w-[1400px] items-center justify-between px-5 ${paddingClass} transition-all`}>
          <div className='flex items-center justify-start gap-[22px]'>
            <Link href='/' className='w-[180px] flex-col items-center justify-start gap-2.5 transition-opacity hover:opacity-80'>
              <Image width={180} height={40} src='/assets/images/novo-logo-verde-branco.png' alt='NoPing Logo' />
            </Link>
          </div>

          <div className='flex w-full items-center justify-end gap-20'>
            {variant === 'primary' && (
              <div className='hidden items-start justify-start gap-10 xl:flex'>
                <ActiveLink activeClassName='!border-lime-300' href='/' className='border-b-2 border-transparent text-center text-sm font-bold uppercase leading-[30.80px] text-slate-300 hover:border-lime-300'>
                  {t('header.home')}
                </ActiveLink>
                <ActiveLink activeClassName='!border-lime-300' href='/games' className='border-b-2 border-transparent text-center text-sm font-bold uppercase leading-[30.80px] text-slate-300 hover:border-lime-300'>
                  {t('header.games')}
                </ActiveLink>
                <ActiveLink activeClassName='!border-lime-300' href='/pricing' className='border-b-2 border-transparent text-center text-sm font-bold uppercase leading-[30.80px] text-slate-300 hover:border-lime-300'>
                  {t('header.pricing')}
                </ActiveLink>
                <ActiveLink activeClassName='!border-lime-300' href='/download' className='border-b-2 border-transparent text-center text-sm font-bold uppercase leading-[30.80px] text-slate-300 hover:border-lime-300'>
                  {t('header.download')}
                </ActiveLink>
                <ActiveLink activeClassName='!border-lime-300' href='/affiliates' className='border-b-2 border-transparent text-center text-sm font-bold uppercase leading-[30.80px] text-slate-300 hover:border-lime-300'>
                  {t('header.affiliates')}
                </ActiveLink>
                <ActiveLink activeClassName='!border-lime-300' href='/support' className='border-b-2 border-transparent text-center text-sm font-bold uppercase leading-[30.80px] text-slate-300 hover:border-lime-300'>
                  {t('header.support')}
                </ActiveLink>
              </div>
            )}

            {variant === 'primary' && (
              <div className='flex gap-8 lg:gap-10'>
                <div className='hidden items-center gap-10 xl:flex'>
                  <LanguageSwitcher />
                  <div className='flex items-center justify-start gap-2.5'>
                    <SignInModal />
                  </div>
                  <div>
                    <Button onClick={() => router.push('/trial')} variant='white' size='sm' className={buttonClass}>
                      <span className='font-bold'>{t('header.button_free_trial')}</span>
                    </Button>
                  </div>
                </div>

                <div className='flex cursor-pointer text-5xl text-white xl:hidden' onClick={toggleMenu}>
                  {menuOpen ? <IoClose /> : <IoMenu />}
                </div>
              </div>
            )}
          </div>

          {variant === 'secondary' && (
            <div className='hidden items-center justify-start gap-2.5 mobile:flex'>
              <Link href='/' className='border-b border-transparent text-center text-sm font-bold uppercase leading-[30.80px] text-neutral-50 transition-all hover:border-b-white'>
                {t('header.back_to_site')}
              </Link>
            </div>
          )}
        </div>
      </div>

      {menuOpen && (
        <div className='fixed inset-0 z-40 flex size-full flex-col items-center justify-center gap-5 bg-black'>
          <Link href='/pricing' className='w-full rounded-md px-2 text-center text-xl font-bold uppercase text-slate-300 hover:bg-white/20' onClick={closeMenu}>
            {t('header.pricing')}
          </Link>
          <Link href='/games' className='w-full rounded-md px-2 text-center text-xl font-bold uppercase text-slate-300 hover:bg-white/20' onClick={closeMenu}>
            {t('header.games')}
          </Link>
          <Link href='/download' className='w-full rounded-md px-2 text-center text-xl font-bold uppercase text-slate-300 hover:bg-white/20' onClick={closeMenu}>
            {t('header.download')}
          </Link>
          <Link href='/affiliates' className='w-full rounded-md px-2 text-center text-xl font-bold uppercase text-slate-300 hover:bg-white/20' onClick={closeMenu}>
            {t('header.affiliates')}
          </Link>
          <Link href='/support' className='w-full rounded-md px-2 text-center text-xl font-bold uppercase text-slate-300 hover:bg-white/20' onClick={closeMenu}>
            {t('header.support')}
          </Link>
        </div>
      )}
    </>
  );
}
