import 'keen-slider/keen-slider.min.css';

import { useKeenSlider } from 'keen-slider/react';
import { LoaderCircle } from 'lucide-react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

const animation = { duration: 10000, easing: (t: number) => t };

const items = [
  {
    name: 'adrenaline',
    image: '/assets/images/portals/adrenaline.webp',
    url: 'https://www.adrenaline.com.br/games/noping-o-que-e-como-funciona-e-como-usar-em-jogos/',
  },
  {
    name: 'claro',
    image: '/assets/images/portals/claro.webp',
    url: 'https://www.claro.com.br/claro-gaming/residencial',
  },
  {
    name: 'freemmostation',
    image: '/assets/images/portals/freemmostation.webp',
    url: 'https://www.freemmostation.com/guides/ffxiv-latency-fix-tips-that-you-can-easily-apply/',
  },
  {
    name: 'maisesports',
    image: '/assets/images/portals/maisesports.webp',
    url: 'https://maisesports.com.br/noping-ajuda-a-reduzir-o-lag-em-jogos-online-e-melhora-o-seu-fps/',
  },
  {
    name: 'mmohuts',
    image: '/assets/images/portals/mmohuts.webp',
    url: 'http://mmohuts.com/news/ffxiv-best-server-new-players-find-new-home/',
  },
  {
    name: 'mmorpg',
    image: '/assets/images/portals/mmorpg.webp',
    url: 'https://www.mmorpg.com/general-articles/reduce-your-ping-in-your-favorite-games-with-noping-sponsored-2000128736',
  },
  {
    name: 'onrpg',
    image: '/assets/images/portals/onrpg.webp',
    url: 'https://www.onrpg.com/news/editorial/how-to-fix-lost-ark-lag/',
  },
].map((item, index) => ({
  ...item,
  id: index + 1,
}));

export default function Sponsors() {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation('pages/home');
  const router = useRouter();
  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    drag: false,
    breakpoints: {
      '(min-width: 1024px)': {
        slides: {
          spacing: 75,
          perView: 4,
        },
      },
    },
    slides: {
      spacing: 75,
      perView: 2,
    },
    created(s) {
      s.moveToIdx(5, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className='mb-28 flex w-full items-center justify-center'>
        <LoaderCircle size={100} className='animate-spin text-green-300' />
      </div>
    );
  }

  return (
    <div className='flex items-center justify-center'>
      <div className='w-full max-w-[1400px] p-5 lg:pb-40'>
        {router.pathname === '/' && <h2 className='text-8-5xl font-bold leading-[110%] text-white'>{t('in_midia')}</h2>}
        <div ref={sliderRef} className='keen-slider portals flex items-center'>
          {items.map((item) => (
            <a href={item.url} target='_blank' key={item.id} className={`keen-slider__slide number-slide flex h-[100px] w-[200px] items-center justify-center${item.id}`}>
              <Image src={item.image} width={200} height={200} alt={item.name} className='cursor-pointer grayscale transition-all hover:grayscale-0' />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
