import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import CloseIcon from '@/assets/svg/close-icon';
import EyeIcon from '@/assets/svg/eye-icon';
import Button from '@/common/button';
import { useAuth } from '@/hooks/use-auth';
import type { AppDispatch } from '@/store';
import { forgotPassword } from '@/store/user';

import SignUpModal from './sign-up-modal';

const defaultValues = {
  login: '',
  password: '',
  recaptcha: '',
};

const defaultValuesResetPassword = {
  email: '',
};

interface FormData {
  login: string;
  password: string;
  recaptcha: string;
}

interface FormDataResetPassword {
  email: string;
}

interface SignInModalProps {
  classNameButton?: string;
  textButtonLogin?: string;
  affiliatePage?: boolean;
}

export default function SignInModal({ classNameButton, textButtonLogin, affiliatePage }: SignInModalProps) {
  const auth = useAuth();
  const { user } = auth;
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [showModaCreatelUser, setShowModalCreateUser] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation('common');
  const schema = yup.object().shape({
    login: yup.string().email(t('login.mensage_error_empty_email')).required(t('login.mensage_error_empty_email')),
    password: yup.string().required(t('login.mensage_error_empty_password')),
    recaptcha: yup.string().required(t('login.mensage_error_empty_captch')),
  });

  const resetPasswordSchema = yup.object().shape({
    email: yup.string().email(t('login.mensage_error_empty_email')).required(t('login.mensage_error_empty_email')),
  });

  const openModal = useCallback(() => {
    if (user) {
      if (affiliatePage) {
        router.push('/dashboard/affiliate');
      } else {
        router.push('/dashboard/overview');
      }
      return;
    }
    setIsOpen(true);
  }, [user, affiliatePage, router]);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const {
    register,
    setError,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onBlur',
  });

  const onSubmit = useCallback(
    async (data: FormData) => {
      setLoading(true);
      try {
        await auth.login(data);
      } catch (error) {
        setError('login', {
          type: 'manual',
          message: (error as Error).message || t('login.mensage_error_user_does_not_exist'),
        });
      } finally {
        setLoading(false);
      }
    },
    [auth, setError, t]
  );

  const openModalSingUp = useCallback(() => {
    setShowModalCreateUser(true);
    setIsOpen(false);
    setShowForgotPassword(false);
  }, []);

  const closeModalSingUp = useCallback(() => {
    setShowModalCreateUser(false);
    setIsOpen(true);
  }, []);

  const handleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const handleRecaptchaChange = useCallback(
    (token: string | null) => {
      setValue('recaptcha', token ?? '');
      clearErrors('recaptcha');
    },
    [setValue, clearErrors]
  );

  const handleRecaptchaExpired = useCallback(() => {
    setValue('recaptcha', '');
    setError('recaptcha', { type: 'required' });
  }, [setValue, setError]);

  useEffect(() => {
    if (user) {
      closeModal();
    }
  }, [closeModal, user]);

  function onSubmitResetPassword({ email }: FormDataResetPassword) {
    setLoading(true);
    dispatch(forgotPassword(email))
      .then((res) => {
        // @ts-ignore
        if (res.payload?.status !== 200) {
          // @ts-ignore
          const errorMessage = Object.keys(res.payload.error).length > 0 ? res.payload.error.message : `Internal error, please contact support.`;
          toast.error(errorMessage, {
            position: 'top-center',
          });
          setLoading(false);
          return;
        }
        setSuccess(true);
        setLoading(false);
      })
      .catch(() => {
        toast.error(`Internal error, please contact support.`, {
          position: 'top-center',
        });
        setLoading(false);
      });
  }

  const {
    register: registerRegister,
    handleSubmit: handleResetPassword,
    formState: { errors: errorResetPassword },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: defaultValuesResetPassword,
    mode: 'onBlur',
  });

  return (
    <>
      <button type='button' onClick={openModal} className={classNameButton || `text-center text-sm font-bold uppercase leading-[30.80px] text-neutral-50`}>
        {user?.name ? t('header.my_account') : textButtonLogin || t('header.button_login')}
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 scale-95' enterTo='opacity-100 scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full max-w-[530px] overflow-hidden rounded-xl border border-[#515E6C]/55 bg-[#1D212A] p-10 text-left align-middle shadow-xl transition-all'>
                  <div className='flex items-center justify-between'>
                    <Dialog.Title as='h3' className='text-2xl font-semibold leading-6 text-white'>
                      {!showForgotPassword ? t('login.already_registered') : t('login.forgot_password.title')}
                    </Dialog.Title>
                    <button onClick={closeModal} className='text-lg font-semibold text-white hover:text-gray-800'>
                      <CloseIcon />
                    </button>
                  </div>

                  <div className='mt-4'>
                    {!showForgotPassword ? (
                      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2.5'>
                        <div className='flex w-full items-center rounded-xl border border-[#879BAB] px-10 py-4'>
                          <input placeholder={t('login.placeholder.email')} type='string' {...register('login')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
                        </div>
                        {errors.login && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.login?.message}</span>}
                        <div className='flex w-full items-center rounded-xl border border-[#879BAB] px-10 py-4'>
                          <input placeholder={t('login.placeholder.password')} type={showPassword ? 'text' : 'password'} {...register('password')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
                          <span className='cursor-pointer text-[#515F6C] transition-all hover:opacity-70' onClick={handleShowPassword}>
                            <EyeIcon />
                          </span>
                        </div>
                        {errors.password && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.password?.message}</span>}

                        <div className='flex flex-col justify-between gap-2 mobile:flex-row mobile:items-center'>
                          <div className='flex items-center gap-2'>
                            <input type='checkbox' id='emailCheckbox' className='form-checkbox size-3 rounded-sm border border-white bg-white text-green-600 checked:bg-green-600' />
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label htmlFor='emailCheckbox' className='cursor-pointer text-xs font-medium leading-[140%] text-white'>
                              {t('login.remember_email')}
                            </label>
                          </div>

                          <div className=''>
                            <button type='button' className='text-xs font-medium text-green-300' onClick={() => setShowForgotPassword(true)}>
                              <span>{t('login.forgot_password.title')}</span>
                            </button>
                          </div>
                        </div>

                        <div className='mt-2 flex flex-col place-content-center gap-5'>
                          <div className='text-center'>
                            <div className='dispaly:inline-block'>
                              <ReCAPTCHA sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY ?? ''} onChange={handleRecaptchaChange} onExpired={handleRecaptchaExpired} />
                            </div>
                          </div>
                          {errors.recaptcha && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors.recaptcha?.message}</span>}
                        </div>

                        <div>
                          <Button type='submit' className='py-3' variant='primary' fullWidth loading={loading}>
                            <span className='text-sm'>{t('login.button_sign_in')}</span>
                          </Button>
                        </div>
                      </form>
                    ) : (
                      <form onSubmit={handleResetPassword(onSubmitResetPassword)} className='flex flex-col'>
                        <p className='text-base font-medium leading-[140%] text-[#7C7C89]'>{t('login.forgot_password.description')}</p>

                        <div className='mt-4 flex w-full items-center rounded-xl border border-[#879BAB] px-10 py-4'>
                          <input placeholder={t('login.placeholder.email')} type='string' {...registerRegister('email')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
                        </div>
                        {errorResetPassword.email && <span className='ml-1 mt-2 flex items-center text-xs font-medium tracking-wide text-red-500'>{errorResetPassword.email?.message}</span>}
                        <div className='mt-4 flex w-full flex-col gap-4 mobile:flex-row'>
                          <Button type='button' variant='gray-light' fullWidth onClick={() => setShowForgotPassword(false)}>
                            {t('login.forgot_password.cancel_button')}
                          </Button>
                          <Button type='submit' variant='primary' fullWidth loading={loading}>
                            {t('login.forgot_password.button')}
                          </Button>
                        </div>
                        {success && <p className='ml-1 mt-4 text-sm font-bold text-white'>{t('login.forgot_password.send_email')}</p>}
                      </form>
                    )}

                    <div className='mt-10'>
                      <h3 className='mb-1 text-2xl font-bold text-white'>{t('login.not_registered')}</h3>
                      <p className='!mb-5 text-base font-medium leading-[140%] text-[#7C7C89]'>{t('login.not_registered_description')}</p>

                      <Button type='button' className='py-3' variant='gray' fullWidth onClick={openModalSingUp}>
                        <span className='text-sm text-[#C8D4E2]'>{t('login.button_sign_up')}</span>
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {affiliatePage ? showModaCreatelUser && <SignUpModal closeModalSingUp={closeModalSingUp} showModalSingIn={openModal} otherRouterPushPage='dashboard/affilaite' /> : showModaCreatelUser && <SignUpModal closeModalSingUp={closeModalSingUp} showModalSingIn={openModal} />}
    </>
  );
}
